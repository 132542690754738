<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="webIcon" @click="toWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://d2xtj2pb46a9pz.cloudfront.net");
    },
    toTg() {
      window.open("https://t.me/tiktokgfq");
    },
    toPotato() {
      window.open("https://dljpt6.org/tiktokgfq");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    left: 190px;
    top: 70px;
    height: 104px;
    width: 386px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    right: 190px;
    top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .webIcon {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 35px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 35px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 813px;
    width: 993px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .logoText {
    height: 294px;
    width: 569px;
    background: url("./../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 207px;
    bottom: 500px;
  }
  .downloadBox {
    height: 324px;
    width: 408px;
    background: url("./../../../assets/images/pc/downloadBox.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 195px;
    bottom: 86px;
    .qrcodeBox {
      position: absolute;
      right: 9px;
      top: 66px;
      height: 210px;
      width: 210px;
      background-color: white;
    }
  }
}
</style>
